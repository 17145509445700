import React from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  InputGroup,
  Form,
  FormControl,
} from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faLightbulb } from '@fortawesome/free-solid-svg-icons';

import 'react-notifications/lib/notifications.css';
import '../assets/css/modal.css';


// import data from '../d3-conviscope/resources/test_preliminary_overview.json';

export default class QuickScan extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleOpenOverview = this.handleOpenOverview.bind();
    this.handleCloseOverview = this.handleCloseOverview.bind();
    this.createNotification = this.createNotification.bind();
    
    this.state = {
      show: false,
      data: null, // Change to null when fetch enabled
      wordcloudImg: null,
      dataset_id: this.props.dataset_id,
      isLoadedDataset: false, // Change to false when fetch enabled
      isLoadedWordcloud: false,
      error: null,
    };

    this.addShortCutOverview();
  }

  // DocString: Key shortcut(F6) - opens modal
  addShortCutOverview = () => {
    document.onkeyup = function (e) {
      if (e.which == 117) {
        document.getElementById('snapShotoverview').click();
        // alert("F6 key was pressed");
      } else {
      }
    };
  };

  // BUTTON FUNCTIONS ===============================================================
  // DocString: Closes window
  // Effects  : Clears graph, filter, comments fields
  handleCloseOverview = () => {
    this.setState({ show: false });
  };

  // DocString: Opens window
  // Effects  : Searches and adds graph, filter to populate fields
  //            Generates default dateTimeStamp for file name
  handleOpenOverview = () => {
    this.setState({ show: true });
  };

  createNotification = () => {
  };

  componentDidMount() {
    // const BASE_URL = process.env.REACT_APP_BASE_URL;
    const BASE_URL = process.env.REACT_APP_CONVIS_URL;
    // const BASE_URL = "https://staging.app.statlabs.io" // for staging env
    // const BASE_URL = 'https://beta.convis.statlabs.io'; // for dev env
// const BASE_URL = 'http://localhost:86'; // for local dev env

    const req = {
      dataset_id: this.state.dataset_id
    };

    axios
      .post(BASE_URL + '/api/preliminary_overview/fetch', JSON.stringify(req), {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((result) => {
        if (result.status === 200) {
          this.setState({
            isLoadedDataset: true,
            data: result.data.payload,
          });
        }
      })
      .catch((e) => {
        console.log('oof');
        console.log(e.response);
        if (e.response) {
          if (e.response.status === 400) {
            this.setState({
              isLoadedDataset: true,
              data: e.response,
              error: e.response,
            });
          }
        }
      });

    axios
      .post(BASE_URL + '/api/preliminary_overview/images', JSON.stringify(req), {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((result) => {
        if (result.status === 200) {
          this.setState({
            isLoadedWordcloud: true,
            wordcloudImg: result.data.wordcloud_img,
            genderDistributionImg: result.data.gender_distribution_img,
            mostUsedTopicsImg: result.data.most_used_topics_img,
            leastUsedTopicsImg: result.data.least_used_topics_img,
            mostUsedSubtopicsImg: result.data.most_used_sub_topics_img,
            leastUsedSubtopicsImg: result.data.least_used_sub_topics_img,
            overallSentiImg: result.data.overallsenti_img,
            sentiGenderImg: result.data.sentigender_img,
            sentiAgeGroupimg: result.data.sentiagegroup_img
          });
        }
      })
      .catch((e) => {
        console.log('oof');
        console.log(e.response);
        if (e.response) {
          if (e.response.status === 400) {
            this.setState({
              isLoadedWordcloud: true,
              data: e.response,
              error: e.response,
            });
          }
        }
      });
    }
  
  // HTML ===============================================================
  // DocString: Renders modal with open and close buttons
  render() {
    const modalStyle = {
      margin: '10 auto',
      transition: 'all .8s',
    };

    const headerStyle = {
      background: 'lightSteelBlue',
    };

    const bodyStyle = {
      background: '#F6F6F6',
    };

    const footerStyle = {
      background: 'White',
    };

    const row = {
      marginLeft: 1,
      marginRight: 1,
    };

    const { error, isLoadedDataset, isLoadedWordcloud } = this.state;

    if (error) {
      console.log(error.data.details);
      return <div>Error: {error.data.details}</div>;
    } else if (!isLoadedDataset || !isLoadedWordcloud) {
      return (
      <div>
        <Button
            className="float-custom-quickscan"
            id="snapShotoverview"
            onClick={this.handleOpenOverview}
            //rotate={true}
          >
            <FontAwesomeIcon icon={faLightbulb} className="" />
        </Button>
        <Modal id='OverviewModal'
            centered
            style={modalStyle}
            className="modal-style"
            show={this.state.show}
            onHide={this.handleCloseOverview}
            animation={false}
          >
          <Modal.Header style={headerStyle} closeButton>
              <Modal.Title>Snapshot</Modal.Title>
          </Modal.Header>
  
          <Modal.Body
              style={bodyStyle}
              className="modal-content show-grid"
              // className="show-grid"
            >
            <Row>
              Loading...
            </Row>
          </Modal.Body>
        </Modal>
      </div>
      )
    } else {
      // const source = "\"data:image/png;base64," + this.state.wordcloudImg + "\"";
      // console.log(source);
      return (
        <div>
          <Button
            className="float-custom-quickscan"
            id="snapShotoverview"
            onClick={this.handleOpenOverview}
            //rotate={true}
          >
            <FontAwesomeIcon icon={faLightbulb} className="" />
          </Button>
  
          <Modal id='OverviewModal'
            centered
            style={modalStyle}
            className="modal-style"
            show={this.state.show}
            onHide={this.handleCloseOverview}
            animation={false}
          >
            <Modal.Header style={headerStyle} closeButton>
              <Modal.Title>Snapshot</Modal.Title>
            </Modal.Header>
  
            <Modal.Body
              style={bodyStyle}
              className="modal-content show-grid"
              // className="show-grid"
            >
              <Row>
                <Col className="col-lg-12 col-md-12 col-sm-12">
                  <Row>
                    <Col xs={12}  sm={12} md={6} lg={6}>
                      <h5>Your Population</h5>
                      <ul className="overview-listing population">
                        <li> {JSON.parse(JSON.stringify(this.state.data.engagement[0].total_conversation))}</li>
                        <li> {JSON.parse(JSON.stringify(this.state.data.engagement[1].userGender_info))}</li>
                        <li> {JSON.parse(JSON.stringify(this.state.data.engagement[2].age_distribution))}</li>
                      </ul>
                    </Col>
                    <Col  className="pr-0 pl-0" xs={12} sm={12} md={3} lg={3}>
                    <img
                      src={"data:image/png;base64," + this.state.genderDistributionImg}
                      className="img-fluid"
                      alt="Gender Distribution"></img>
                    </Col>
                    <Col  className="pr-0 pl-0" xs={12}  sm={12} md={3} lg={3}>
                    <img
                    src={"data:image/png;base64," + this.state.wordcloudImg}
                    className="img-fluid"
                    alt="Under Construction"
                    // style={{ width: '100%', margin:'0 auto', display:'block' }}
                    style={{ marginTop: '-65px'}}
                    ></img>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-0 pl-0" xs={12}  sm={12} md={4} lg={3}>
                      <img
                        src={"data:image/png;base64," + this.state.mostUsedTopicsImg}
                        className="img-fluid"
                        alt="Most Used Topics"></img>
                      </Col>
                      <Col className="pr-0 pl-0" xs={12}  sm={12} md={4} lg={3}>
                        <img
                          src={"data:image/png;base64," + this.state.mostUsedSubtopicsImg}
                          className="img-fluid"
                          alt="Most Used Subtopics"></img>
                      </Col>
                      <Col className="pr-0 pl-0" xs={12}  sm={12} md={4} lg={3}>
                        <img
                          src={"data:image/png;base64," + this.state.leastUsedTopicsImg}
                          className="img-fluid"
                          alt="Least Used Topics"></img>
                      </Col>
                      <Col className="pr-0 pl-0" xs={12}  sm={12} md={4} lg={3}>
                      <img
                        src={"data:image/png;base64," + this.state.leastUsedSubtopicsImg}
                        className="img-fluid"
                        alt="Least Used Subtopics"></img>
                      </Col>
                    </Row>
                  {/* <h5>Your Population</h5>  
                  <ul className="overview-listing">
                    <li> {JSON.parse(JSON.stringify(this.state.data.engagement[0].total_conversation))}</li>
                    <li> {JSON.parse(JSON.stringify(this.state.data.engagement[1].userGender_info))}</li>
                    <li> {JSON.parse(JSON.stringify(this.state.data.engagement[2].age_distribution))}</li>
                  </ul> */}
                  {/* <h5>Trends</h5>
                    <ul className="overview-listing">
                      <li> {JSON.parse(JSON.stringify(this.state.data.trends[0].most_talked_t))}</li>
                      <li> {JSON.parse(JSON.stringify(this.state.data.trends[1].most_talked_st))}</li>
                      <li> {JSON.parse(JSON.stringify(this.state.data.trends[2].least_talked_t))}</li>
                      <li> {JSON.parse(JSON.stringify(this.state.data.trends[3].least_talked_st))}</li>
                    </ul> */}
                </Col>
                {/* <Col className="col-lg-3 col-md-6 col-sm-6">
                  <img
                    src={"data:image/png;base64," + this.state.wordcloudImg}
                    className="img-fluid"
                    alt="Under Construction"
                    style={{ width: '100%', margin:'0 auto', display:'block' }}
                    ></img>
                </Col> */}
                <Col className="col-lg-12 col-md-12 col-sm-12">
                  <div className="" >
                    <h5  style = {{marginTop: '1.5rem'}}> Comparison</h5>
                    <ul className="overview-listing">
                      <li>{JSON.parse(JSON.stringify(this.state.data.comparison[0].trends_list_0))}</li>
                      <li>{JSON.parse(JSON.stringify(this.state.data.comparison[1].trends_list_1))}</li>
                        {JSON.parse(JSON.stringify(this.state.data.comparison[2].trends_list_2)) != "novalue" &&
                        <li>{JSON.parse(JSON.stringify(this.state.data.comparison[2].trends_list_2))}</li>
                      }
                    </ul>
                  </div>
                </Col>
                <Row className="col">
                    <Col  className="" xs={12}  sm={12} md={12} lg={12}>
                      <div className="" >
                      <h5>Sentiment</h5>
                      </div>
                    </Col>
                      <Col  className="pr-0 pl-0" xs={12}  sm={12} md={4} lg={3}>
                      <ul className="overview-listing">
                      <li>{JSON.parse(JSON.stringify(this.state.data.sentimental[0].overallsentimental))}</li>
                      {JSON.parse(JSON.stringify(this.state.data.sentimental[1].senti_text_positive_main)) != "novalue" &&
                      <li>{JSON.parse(JSON.stringify(this.state.data.sentimental[1].senti_text_positive_main))}</li>
                      }
                      {JSON.parse(JSON.stringify(this.state.data.sentimental[2].senti_text_negative_main)) != "novalue" &&
                      <li>{JSON.parse(JSON.stringify(this.state.data.sentimental[2].senti_text_negative_main))}
                      </li>
                      }
                    </ul>
                      </Col>
                      <Col  className="pr-0 pl-0" xs={12}  sm={12} md={4} lg={3}>
                      <img
                        src={"data:image/png;base64," + this.state.overallSentiImg}
                        className="img-fluid"
                        alt="Overall Sentiment"
                    ></img>
                      </Col>
                      <Col  className="pr-0 pl-0" xs={12}  sm={12} md={4} lg={3}>
                      
                      <img
                        src={"data:image/png;base64," + this.state.sentiGenderImg}
                        className="img-fluid"
                        alt="Sentiment Gender"
                    ></img>
                      </Col>
                      <Col  className="pr-0 pl-0" xs={12}  sm={12} md={4} lg={3}>
                    
                      <img
                        src={"data:image/png;base64," + this.state.sentiAgeGroupimg}
                        className="img-fluid"
                        alt="Sentiment Age"
                    ></img>
                      </Col>
                  
                </Row>
  
              </Row>
            </Modal.Body>
  
            {/* <Modal.Footer style={footerStyle}>
              <Button id="reportCloseButton" variant="secondary" onClick={this.handleClose}>
                Cancel
              </Button>
              
            </Modal.Footer> */}
          </Modal>
        </div>
      );
    }
  }
}
