import React from 'react';
import * as d3 from 'd3';
import '../assets/css/sidebar.css';


export default class Conversations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      edit: false, 
    };
  }

  triggerToggle = () => {
    // CHANGE THIS PROP INSTEAD
    this.setState({ toggle: !this.state.toggle });
  };

  render() {
    return (
      <div className="conversation-container-box">
        <div
          // className="conversation-trigger"
          className={'conversation-trigger ' + this.state.toggle ? 'triggerToggleOn' : 'triggerToggleOff'}
        >
          <button className="closebtn" onClick={this.triggerToggle}>
            &#9776;
          </button>
          <p className="conversation-trigger-text mt-3 mb-0">Conversations</p>
        </div>
        <div
          // className="conversation-content"
          className={'conversation-content '+ this.state.toggle ? 'contentToggleOn' : 'contentToggleOff'}
        >
          <div className="conversation-content-title">
            <div className="px-2 py-2">
              <span style={{ color: 'white' }}>Conversations</span>
            </div>
            <div class="btn-group btn-group-toggle toggle-group" data-toggle="buttons">
              <label class="btn btn-outline-light btn-sm conv-button active">
                <input type="radio" name="view" id="conv-view" autocomplete="off" checked/> View
              </label>
              <label class="btn btn-outline-light btn-sm conv-button">
                <input type="radio" name="edit" id="conv-edit" autocomplete="off" /> Validate
              </label>
            </div>
            {/* <button type="button" id="annotation-export" class="btn btn-outline-light btn-sm conv-button" onClick={this.exportAnnotation}>Export</button> */}
            <button type="button" id="annotation-export" class="btn btn-outline-light btn-sm conv-button" >Export</button>
            <button className="openbtn" onClick={this.triggerToggle}>
              &times;
            </button>
          </div>
          <div
            className="content-container"
            id={`content-container-${this.props.id}`}
            // style={{ width: '20%' }}
            // style={{ height: this.props.comparisonOn ? '26.2rem' : '40.1rem' }}
            style={{ height: this.props.comparisonOn ? '30rem' : '40.1rem' }}
          ></div>
        </div>
      </div>
    );
  }
}
