import React from 'react';
import DatasetFilter from '../dataset-filter';
import { Col } from 'react-bootstrap';

import * as d3 from 'd3';
import * as dc from 'dc';

export default class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
    this.dimension = props.crossfilter.dimension((d) => {
      return new Date(d.conversationDate.startDate);
    });
    this.group = this.dimension.group((d) => d3.timeDay(d));
    this.props.addDimension(this.dimension);
  }

  componentDidMount() {
    this.timeChart = new dc.BarChart(this.container, 'timeline');

    this.timeChart
      .width(700)
      .height(100)
      .x(
        d3
          .scaleTime()
          .domain([
            new Date(this.dimension.bottom(1)[0].conversationDate.startDate),
            new Date(this.dimension.top(1)[0].conversationDate.startDate),
          ])
      )
      .margins({ top: 5, right: 5, bottom: 20, left: 20 })
      .elasticX(true)
      .elasticY(true)
      .brushOn(true)
      .clipPadding(10)
      .dimension(this.dimension)
      .group(this.group)
      .colors('gray')
      .yAxis()
      .tickFormat((d) => (d % 1 === 0 ? d3.format('d')(d) : null))
      .ticks(3);

    this.timeChart.xAxis().tickFormat(function (d) {
      let formattedTime = d3.timeFormat('%b')(d);
      if (formattedTime === 'Jan') {
        return d3.timeFormat('%Y')(d);
      }
      return formattedTime;
    });
    // Adding padding in the xAxis so that points at the boundary could be selected
    this.timeChart.xAxisPadding(1).xAxisPaddingUnit();

    this.timeChart.on('pretransition', (chart) => {
      let filterVal = chart.filter() ? chart.filter() : [];
      if (filterVal.length === 2 || this.state.selected.length === 2) {
        if (filterVal.length === this.state.selected.length) {
          if (
            filterVal[0].getTime() !== this.state.selected[0].getTime() ||
            filterVal[1].getTime() !== this.state.selected[1].getTime()
          )
            this.onFilterChange(filterVal);
        } else this.onFilterChange(filterVal);
      }
    });

    this.timeChart.render();
  }

  componentDidUpdate() {
    if (this.props.reset === true && this.state.selected !== []) {
      this.setState({ selected: [] });
      this.timeChart.filterAll();
    }
    this.timeChart.redraw();
  }

  onFilterChange = (val) => {
    console.log("timeline val", val);
    this.setState({ selected: val }, this.props.onFilterChange);
  };

  static formatDate(date) {
    let month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  }

  render() {
    let startDate =
      this.state.selected.length === 2
        ? Timeline.formatDate(this.state.selected[0])
        : '';
    let endDate =
      this.state.selected.length === 2
        ? Timeline.formatDate(this.state.selected[1])
        : '';

    return (
      <Col md={this.props.md} sm={this.props.sm} className="">
        <p className="filter-label">
          {'Conversation Timeline   '}
          {this.state.selected.length === 2 ? (
            <span
              style={{ fontWeight: '400' }}
            >{`( ${startDate} ~ ${endDate} )`}</span>
          ) : null}
        </p>
        <svg
          id={`timeRange-${this.props.id}`}
          viewBox="0 0 700 100"
          ref={(container) => (this.container = container)}
        ></svg>
      </Col>
    );
  }
}
