import React from 'react';
import Body from './body';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { userContext } from '../contexts/auth';
import { datasetContext } from '../contexts/dataset';

import data from '../d3-conviscope/resources/loaded_json.json';

// const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL = process.env.REACT_APP_CONVIS_URL;
// const BASE_URL = "https://staging.app.statlabs.io" // for staging env
// const BASE_URL = 'https://beta.convis.statlabs.io'; // for dev env
// const BASE_URL = 'http://localhost:86'; // for local dev env

export default class Convex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      data: null,
      dataset_id: null,
      isLoadedDataset: false,
      isLoadedUserData: false,
      error: null,
    };
  }

  //  Comment out if the data will be loaded from the csv
  componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);

    let dataset_id = params.get('dataset_id');
    if (dataset_id == null) {
      dataset_id = -1;
    }

    this.setState({
      dataset_id: dataset_id
    })

    const api_key = params.get('api_key');

    console.log('BASE URL : ' + BASE_URL);
    console.log(api_key);

    const cookies = new Cookies();
    // cookies.set('api_key', api_key, { path: '/' , domain: 'localhost' });
    // cookies.set('api_key', api_key, { path: '/', domain: 'mhealth-lab.com' });
    // cookies.set('api_key', api_key, { path: '/', domain: '.statlabs.io' });

    // get and set currently logged in user to state
    axios
      .get(BASE_URL + '/api/user_data', {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((result) => {
        if (result.status === 200) {
          console.log('LOADED');
          this.setState({
            user: {
              username: result.data.username,
              first_name: result.data.first_name,
              last_name: result.data.last_name,
              email: result.data.email,
              role: result.data.role,
              assigned_at: result.data.assigned_at,
            },
            isLoadedUserData: true,
          });
        }
      })
      .catch((e) => {
        console.log('oof');
        console.log(e.response);
        console.log(e);
        if (e.response) {
          if (e.response.status === 400) {
            this.setState({
              user: {},
              isLoadedUserData: true,
              error: e.response,
            });
          }
        }
      });

    const req = {
      dataset_id: dataset_id,
    };
    this.loadDataset(req);
  }

  loadDataset = (req) => {
    axios
      .post(BASE_URL + '/api/nlp_explorer/load', JSON.stringify(req), {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((result) => {
        if (result.status === 200) {
          console.log("successfully receoved data", result);
          this.setState({
            isLoadedDataset: true,
            data: result.data.payload,
          });
          console.log('successfully set Data:', this.state.data);
          console.log('successfully load Data:', this.state.isLoadedDataset);
        }
      })
      .catch((e) => {
        console.log('oof');
        console.log(e);
        console.log(e.response);
        if (e.response) {
          if (e.response.status === 400) {
            this.setState({
              isLoadedDataset: true,
              data: e.response,
              error: e.response,
            });
          }
        }
      });
  }

  render() {
    const { error, isLoadedDataset, isLoadedUserData, items, data } = this.state;
    if (error) {
      console.log(error.data.details);
      return <div>Error: {error.data.details}</div>;
    } else if (!isLoadedDataset || !isLoadedUserData) {
      return <div className="preloader-handler"><div className="preloader-text"><span>Loading..</span><br></br><i class="fa fa-spinner fa-spin"></i></div></div>;
    } else {
      // Pre-processing the data - move to the backend
      console.log('state:', this.state)
      console.log('Data:', data);
      this.state.data.nodes.conversations.forEach((d) => {
        if (d.conversations !== null) {
          d.text = d.conversationText.split('||');
        }
      });
      return (
        <userContext.Provider value={this.state.user}>
          <datasetContext.Provider value={this.state.data}>
            <userContext.Consumer>
              {(value) => <Body loadDataset={this.loadDataset} data={this.state.data} dataset_id={this.state.dataset_id} user={value} />}
            </userContext.Consumer>
          </datasetContext.Provider>
        </userContext.Provider>
      );
    }
  }
}
