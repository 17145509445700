import React from 'react';
import DatasetFilter from '../dataset-filter';
import { Col } from 'react-bootstrap';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

export default class Age extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [0, 0],
    };
    this.dimension = props.crossfilter.dimension(
      (d) => Math.floor(d.metaData.patientInfo.age / 10) * 10
    );
    this.initOptions();
    this.props.addDimension(this.dimension);
  }

  componentDidUpdate() {
    if (this.props.reset === true && this.state.selected !== [0, 0]) {
      console.log('reset age filter');
      this.onFilterChange([0, 0]);
    }
  }

  initOptions = () => {
    let group = this.dimension.group();
    let keyGroup = group.all().map((d) => d.key);
    this.maxVal = Math.max(...keyGroup);
  };

  onFilterChange = (val) => {
    console.log("age val", val);
    this.setState({
      selected: val,
    });

    // Clear all previous filters
    this.dimension.filterAll();

    if (val.length !== 0 && val[1] !== val[0]) {
      if (val[1] === 90) val[1] = Math.max(this.maxVal, 120);
      this.dimension.filterRange(val);
    }
    this.props.onFilterChange();
  };

  render() {
    // set max to the maximum age value if greater than 100
    // let max = this.maxVal > 100 ? this.maxVal : 100;
    // let marks = {};
    // for (let i = 0; i <= Math.floor(max / 20); i++) {
    //   marks[i * 20] = i * 20;
    // }
    return (
      <Col
        md={this.props.md}
        sm={this.props.sm}
        className=""
        id={`ageRange-${this.props.id}`}
      >
        <p className="filter-label mb-1">Age</p>
        <Slider.Range
          dots
          min={0}
          max={90}
          value={this.state.selected}
          step={10}
          marks={{ 0: '0', 20: '20', 40: '40', 60: '60', 80: '80', 90: '80+' }}
          onChange={this.onFilterChange}
        />
      </Col>
    );
  }
}
