import React from 'react';
import {
  Row,
  Col,
  Card,
  ToggleButtonGroup,
  Button,
  ToggleButton,
  Collapse,
} from 'react-bootstrap';

// Filter Components
import Timeline from './filter-components/timeline';
import Group from './filter-components/group';
import Gender from './filter-components/gender';
import Location from './filter-components/location';
import Age from './filter-components/age';

import '../assets/css/dataset-filter.css';
import { faWeight, faBold } from '@fortawesome/free-solid-svg-icons';

export default class DatasetFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      buttonStatus: false,
      cfUpdated: false,
      reset: false,
    };
    this.dimensions = [];
  }

  static multivalue_filter(values) {
    return function (v) {
      return values.indexOf(v) !== -1;
    };
  }

  handleResetClick = () => {
    this.dimensions.forEach(dimension => dimension.filterAll());
    this.setState({ reset: true }, () => {
      this.setState({ reset: false });
      this.props.onFilterChange();
    });
  };

  updateCrossfilter = () => {

    this.setState({
      cfUpdated: true,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.reset !== this.state.reset) {
      return nextState.reset
    } else {
      return true;
    }
  }

  addDimension = (dimension) => {
    this.dimensions.push(dimension);
  };


  // CHANGES HERE ===========================================================
  render() {
    let currentConvLength = this.props.crossfilter.allFiltered().length;
    const open = this.state.open;

    const coversationCount = {
      fontWeight:"bold",
      textDecoration:"underline",
      fontSize:"1.2rem"
    };

    let toggleButton = (
      <>
        <span>{`Compare: `}</span>
        <ToggleButtonGroup
          name="radio-comparison"
          className="mx-2"
          type="radio"
          defaultValue={false}
          onChange={(val) => {
            this.setState({ buttonStatus: val });
            this.props.setComparison(val);
          }}
        >
          <ToggleButton value={true} className="py-0" variant="secondary">
            On
          </ToggleButton>
          <ToggleButton value={false} className="py-0" variant="secondary">
            Off
          </ToggleButton>
        </ToggleButtonGroup>
      </>
    );

    let applyResetButton = (
      <>
        <Button
          variant="secondary"
          className="py-0 ml-3 mr-1"
          onClick={this.props.onFilterChange}
        >
          Apply
        </Button>
        <Button
          variant="secondary"
          className="py-0 ml-1 mr-2"
          onClick={this.handleResetClick}
        >
          Reset All
        </Button>
      </>
    );

    let filterComponents = (
        <Collapse in={this.state.open}>
          <Card.Body className="p-0 borderColor" id="dataset-filter">
            <Row className="filter-row align-items-center m-0" id="topFilters">
              <Timeline
                md={4}
                sm={12}
                crossfilter={this.props.crossfilter}
                onFilterChange={this.updateCrossfilter}
                reset={this.state.reset}
                id={this.props.id}
                addDimension={this.addDimension}
              />
              <Location
                lg={3}
                md={2}
                sm={12}
                crossfilter={this.props.crossfilter}
                onFilterChange={this.updateCrossfilter}
                reset={this.state.reset}
                id={this.props.id}
                addDimension={this.addDimension}
              />
              <Group
                lg={2}
                md={2}
                sm={12}
                crossfilter={this.props.crossfilter}
                onFilterChange={this.updateCrossfilter}
                reset={this.state.reset}
                id={this.props.id}
                addDimension={this.addDimension}
              />
              <Age
                lg={2}
                md={2}
                sm={12}
                crossfilter={this.props.crossfilter}
                onFilterChange={this.updateCrossfilter}
                reset={this.state.reset}
                id={this.props.id}
                addDimension={this.addDimension}
              />
              <Gender
                lg={2}
                md={2}
                sm={12}
                crossfilter={this.props.crossfilter}
                onFilterChange={this.updateCrossfilter}
                reset={this.state.reset}
                id={this.props.id}
                addDimension={this.addDimension}
              />
              

            </Row>
          </Card.Body>
        </Collapse>
    )

    let visulizationSwitch = (
      <>
        <span>{`Analytics:`}</span>
        <ToggleButtonGroup
          name="radio-comparison"
          className="mx-2"
          type="radio"
          defaultValue={true}
          onChange={(val) => {
            console.log(val);
            this.props.onVisulizationChange(val);
          }}
        >
          <ToggleButton value={true} className="py-0" variant="secondary" >
            Descriptive
          </ToggleButton>
          <ToggleButton value={false} className="py-0" variant="secondary">
            Predictive
          </ToggleButton>
        </ToggleButtonGroup>
      </>
    );
    let counts = (
      this.props.conversationExplorerDisplayed
      ? <span style={coversationCount}>{`( Conversation Count: ${currentConvLength} / ${this.props.data.nodes.conversations.length} )`}</span>
      : <span style={coversationCount}>
        {`Predictor Variables Filter ( Selected Variables Count: ${this.props.selectedTopicsCount} / ${this.props.totalTopicsCount} )`}
        </span>
    )
    return (
      <Card className="mb-2">
        <Card.Header className="card-header p-0">
          <Row className="m-0 no-gutters align-items-center">
            <Col>
              <Button
                onClick={() => this.setState({ open: !open })}
                aria-controls="#dataset-filter"
                aria-expanded={open}
                variant="link"
                id="collapsible-button"
              >
                {`Dataset Filter`}
              </Button>
              {counts}
            </Col>
            {visulizationSwitch}
            {this.props.hasButton && this.props.conversationExplorerDisplayed && !this.props.isComparison ? toggleButton : null}
            {this.props.conversationExplorerDisplayed ? applyResetButton : null}

          </Row>
        </Card.Header>
        {
          this.props.conversationExplorerDisplayed
          ? filterComponents
          : null
        }
        
      </Card>
    );
  }
}
