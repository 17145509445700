import React from 'react';
import './assets/css/App.css';
import 'bootstrap/dist/css/bootstrap.css';

import Main from './components/main';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render(){
      return (
        <div className="App">
          <link
            href="//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css"
            rel="stylesheet"
          ></link>
          <Main />
          {/* <div className="footer">
            <h5>Conversation Explorer Sandbox (2020)</h5>
          </div> */}
          
        </div>
        
      );
  }
}
