/* 
ConViScope Report Saving Feature
Graph functions
*/

// DocString: Adds default file name scheme to fileName element
export function addDefaultFileName() {
    var string = "Conviscope_snapshot_";
    string = string.concat(new Date().toLocaleString());
    string = string.replace(/[.,\/#!$%\^&\*;:{}=\-`~()]/g,"");
    string = string.replace(/\s{2,}/g," ");
    string = string.replace(/\s+/g,"-");
    //document.getElementById("fileField").placeholder = string;
    return string;
}

