import React from 'react';
import questionBank from './questionBank.json';
import { Accordion, Card } from "react-bootstrap";

export default class FaqPageContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { content: [] };
  }

  componentDidMount() {
    this.generateContent();
  }


  checkBreaks = (answer) => {
    let points = answer.split("<br>");
    answer = [];
    for (let idx of points) {
      answer.push(<p key={idx}>{idx}</p>);
    }
    return answer;
  }


  generateContent = () => {
    let newContent = [];
    for (let key in questionBank) {
      let questionBoxes = this.generateQuestionBoxes(questionBank[key]);
      let dv = (
        <div key={key}>
          <div style={{ paddingBottom: "10px", fontSize: "22px" }} id={"faq-header-" + key}>{key}</div>
          <div>
            {questionBoxes}
          </div>
        </div>
      );
      newContent.push(dv);
    }
    this.setState({ content: newContent });
  }


  generateQuestionBoxes = (section) => {
    let boxes = [];
    for (let key in section) {
      boxes.push(this.createQuestion(section[key]));
    }
    return boxes;
  }


  createQuestion = (content) => {
    var ans = this.checkBreaks(content["ans"]);
    var question = content["question"];
    return (
      <div key={question}>
        <Accordion style={{ paddingBottom: "10px" }}>
          <Card style={{ textAlign: "left" }}>
            <Accordion.Toggle as={Card.Header} eventKey={"#collapse" + question}>
              {question}
              <p style={{ float: "right", margin: 0 }}>{"▼"}</p>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey={"#collapse" + question}>
              <Card.Body style={{ paddingBottom: 0 }}>{ans}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    );
  }

  render() {
    return (
      <div style={{ textAlign: "left", backgroundColor: "white" }}>
        <div style={{ display: 'block', justifyContent: 'center', position: 'relative', paddingLeft: "10%", paddingRight: "10%" }}>
          <div>
            <h1 style={{ paddingTop: "1%" }}>
              FAQ <small>Frequently Asked Questions (v0.8.1)</small>
            </h1>
            <h5>*** Work in Progress ***</h5>
            <hr />
          </div>
          <div>
            <div className="panel-group" id="accordion">
              {this.state.content}
            </div>
          </div>
        </div>

        <footer className="p-3" style={{ borderTop: '1px solid #d6d3d3' }}>
          <p className="m-0" style={{ textAlign: "center", fontSize: '1rem', color: '#576777' }}>
            Copyright © 2024 UBC mHealth ConVISation Labs
          </p>
        </footer>
      </div>
    );
  }
}
