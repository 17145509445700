import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Convex from './convex';
import FaqPageContent from './faq-components/faq-tab-content.js';

const Main = () => (
  <Route
    render={({ location }) => (
      <TransitionGroup>
        <CSSTransition key={location.pathname} timeout={100} classNames="fade">
          <Switch location={location}>
            <Route exact path="/" component={Convex} />
            <Route exact path="/FaqPage" component={FaqPageContent} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    )}
  />
);

export default Main;
