import React from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  InputGroup,
  Form,
  FormControl,
} from 'react-bootstrap';
import '../assets/css/modal.css';
import { Editor } from '@tinymce/tinymce-react';
import { addDefaultFileName } from './modalFns/graphFns';
import { createPDF, createPDF_comparison } from './modalFns/exportFns';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { text } from '@fortawesome/fontawesome-svg-core';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { when } from 'jquery';

const saveSvgAsPng = require('save-svg-as-png');

export default class ReportModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleOpen = this.handleOpen.bind();
    this.handleClose = this.handleClose.bind();
    this.handleExport = this.handleExport.bind();
    this.makeFileName = this.defaultFileName.bind(this);
    this.createNotification = this.createNotification.bind();

    this.fileField = React.createRef();
    this.commentField = React.createRef();
    this.snapShot = React.createRef();
    this.svgPanel = React.createRef();
    this.svgPanel2 = React.createRef();
    this.filterPanel = React.createRef();

    this.state = {
      show: false,
      fileFieldHolder: addDefaultFileName(),
      fileFieldValue: '',
      commentFieldValue: '',
      comparisonEnabled: false,
      exportButton: "Export",
    };
    this.addShortCut();
  }

  componentDidUpdate() {
    if (this.state.show === true) {
      this.getFilterPreview();
      this.getSVGPreview();
    }
  }

  // DocString: Key shortcut(F7) - opens modal
  addShortCut = () => {
    document.onkeydown = function (e) {
      if (e.which == 118) {
        document.getElementById('snapShot').click();
        // alert("F7 key was pressed");
      } else {
      }
    };
  };

  // BUTTON FUNCTIONS ===============================================================
  // DocString: Closes window
  // Effects  : Clears graph, filter, comments fields
  handleClose = () => {
    this.handleClearFields();
    this.setState({exportButton: "Export"});
    this.setState({ show: false });
  };

  // DocString: Opens window
  // Effects  : Searches and adds graph, filter to populate fields
  //            Generates default dateTimeStamp for file name
  handleOpen = () => {
    this.setState({ show: true });
    this.defaultFileName();
  };

  // FILE AND COMMENT FIELD FUNCTIONS ===============================================================
  handleClearFields = () => {
    this.setState({ fileFieldValue: '' });
    this.setState({ commentFieldValue: '' });
  };

  handleFileNameChange = (e) => {
    this.setState({ fileFieldValue: this.fileField.current.value }); //e.target.value
  };

  handleEditorChange = (e) => {
    this.setState({ commentFieldValue: e.target.getContent() }); //e.target.getContent()
  };

  // GRAPH & FILTER PREVIEW, DEFAULT FILENAME FUNCTIONS ===================================

  defaultFileName = () => {
    var newName = addDefaultFileName();
    this.setState({ fileFieldHolder: newName });
  };

  // SVG PREVIEW FUNCTIONS =============================================================

  // /////////////////////////////////////////////////////////////////////
  // NOTE: FOREIGN OBJECTS NOT SUPPORTED BY JSPDF AND save-svg-as-png   //
  // /////////////////////////////////////////////////////////////////////

  // DocString: Iterates through components and removes components tagged as <foreign object>
  removeForeignObjects(svgClone) {
    for (let visual of svgClone.getElementsByTagName('g')) {
      try {
        visual.removeChild(visual.getElementsByTagName('foreignObject')[0]);
      } catch (error) {
        ;
      }
    }
    return svgClone;
  }

  // DocString: Takes svg data and adds it to report modal
  getSVGPreview = () => {
    var svg = document.getElementById('visualization-container-1');
    var svgClone = svg.cloneNode(true);
    svgClone.removeChild(svgClone.getElementsByTagName('foreignObject')[0]);

    // NEW CODE ITERATE THROUGH VISUALS, REFACTORRRRRRRRRRRRRRRRRRRR
    svgClone = this.removeForeignObjects(svgClone);

    this.svgPanel.current.innerHTML = '';
    this.svgPanel.current.appendChild(svgClone);

    try {
      var svg2 = document.getElementById('visualization-container-2');
      var svgClone2 = svg2.cloneNode(true);
      svgClone2.removeChild(svgClone2.getElementsByTagName('foreignObject')[0]);

      // NEW CODE ITERATE THROUGH VISUALS, REFACTORRRRRRRRRRRRRRRRRRRR
      svgClone2 = this.removeForeignObjects(svgClone2);

      this.svgPanel2.current.innerHTML = '';
      this.svgPanel2.current.appendChild(svgClone2);
      //this.setState({comparisonEnabled:true})
      this.setComparisonEnabled();
    } catch (Exception) {
      this.svgPanel2.current.innerHTML = '';
      //this.setState({comparisonEnabled:false})
    }
  };

  // DocString: Changes sub-title text io show whether comparison feature is on or off
  setComparisonEnabled() {
    let doc = document.getElementById("comparisonIndicator");
    doc.innerText = "Comparison Visualisation"
  }


  // FILTER AND COMMENTS INFORMATION FUNCTIONS =============================================================

  getFilterPreviewSupport(elementID, header) {
    // Getting dorpdown filter data
    var banned = ['Group', 'Gender', 'Age', 'Location'];
    var replacer = 'None Applied!';

    let range = document.getElementById(elementID);
    let data = range.getElementsByClassName('dropdown-heading-value')[0];
    let span = data.getElementsByTagName('span')[0];
    var text = '';
    if (!banned.includes(span.innerText)) {
      text = header.concat(span.innerText, '|');
    } else {
      text = header.concat(replacer, '|');
    }
    return text;
  }

  getFilterPreview = () => {};

  // EXPORT FUNCTIONS ================================================================
  // DocString: Gets file name or defaultname if not specified
  // Returns  : string (filename) + ".pdf"
  getFileName() {
    var holder = this.state.fileFieldHolder;
    var value = this.state.fileFieldValue;
    if (value == '') {
      return holder + '.pdf';
    } else {
      return value + '.pdf';
    }
  }

  // DocString: Get tinymce text values
  // Returns  : String (comments text)
  getComments() {
    return this.state.commentFieldValue;
  }

  // DocString: Gets filter values as text
  getFilterText(elementID, header) {
    // Getting dorpdown filter data
    var banned = ['Group', 'Gender', 'Age', 'Location'];
    var replacer = 'None Applied';

    let range = document.getElementById(elementID);
    let data = range.getElementsByClassName('dropdown-heading-value')[0];
    let span = data.getElementsByTagName('span')[0];
    var text = '';
    if (!banned.includes(span.innerText)) {
      text = header.concat(span.innerText, '<br>');
    } else {
      text = header.concat(replacer, '<br>');
    }
    return text;
  }

  // DocString: Returns Age Slider filter values
  getAgeSliderText(elementID) {
    // Age filter range data
    let ageRange = document.getElementById(elementID);
    let slider1 = ageRange.getElementsByClassName(
      'rc-slider-handle rc-slider-handle-1'
    )[0];
    let slider2 = ageRange.getElementsByClassName(
      'rc-slider-handle rc-slider-handle-2'
    )[0];

    let var1 = slider1.getAttribute('aria-valuenow');
    let var2 = slider2.getAttribute('aria-valuenow');
    let result = "";
  
    if (var1 === var2) {
      result = "Age Range: None Applied<br>"
    } else {
      result = 'Age Range: '.concat(
        var1,
        ' - ',
        var2,
        '<br>');
    }

    return result;
  }

  // DocString: Returns Time Filter values
  getTimeLineText(elementID) {
    let chart = document.getElementById(elementID);
    let dateContainer = chart.getElementsByClassName('chart-body')[0];
    let dates = dateContainer.getElementsByTagName('g')[0];
    let timeText = '';
    try {
      let startDate = dates.querySelectorAll('.bar:not(.deselected)')[0];
      let endDate = dates.querySelectorAll('.bar:not(.deselected)')[
        dates.querySelectorAll('.bar:not(.deselected)').length - 1
      ];
      timeText = 'Timeline: '.concat(
        startDate.childNodes[0].textContent.substring(0, 15),
        ' - ',
        endDate.childNodes[0].textContent.substring(0, 15)
      );
    } catch (exception) {
      timeText = 'Timeline: -'; //"Timeline: No conversations in timeline range"
    } finally {
      return timeText;
    }
  }

  // DocString: Returns filter info of first visualization filters
  getFilters() {
    // Group/Location/Gender filter data
    let groupText = this.getFilterText('groupRange-1', 'Group Range: ');
    let genderText = this.getFilterText('genderRange-1', 'Gender Range: ');
    let locationText = this.getFilterText(
      'locationRange-1',
      'Location Range: '
    );

    // Age Slider Text Function
    let ageText = this.getAgeSliderText('ageRange-1');

    // Time filter range data
    let timeText = this.getTimeLineText('timeRange-1');

    return groupText.concat(locationText, ageText, genderText, timeText);
  }

  // DocString: Returns filter info of second comparison visualization filters
  getFilters2() {
    // Group/Location/Gender filter data
    let groupText = this.getFilterText('groupRange-2', 'Group Range: ');
    let genderText = this.getFilterText('genderRange-2', 'Gender Range: ');
    let locationText = this.getFilterText(
      'locationRange-2',
      'Location Range: '
    );

    // Age Slider Text Function
    let ageText = this.getAgeSliderText('ageRange-2');

    // Time filter range data
    let timeText = this.getTimeLineText('timeRange-2');

    return groupText.concat(locationText, ageText, genderText, timeText);
  }

  // DocSTring:
  getMetaData() {
    let bannerElement = document.getElementById('bannerElement');
    let cards = bannerElement.childNodes;
    
    var dataText = '';
    for (var c of cards) {
      dataText = dataText.concat(
        c.getElementsByTagName('span')[0].innerText,
        ': '
      );
      dataText = dataText.concat(
        c.getElementsByClassName('card-text')[0].innerText,
        '<br>'
      );
    }
    return dataText;
  }

  createNotification = () => {
  };

  // EXPORT BUTTON FUNCTIONS ========================================================================
  // DocString: Closes window and exports PDF
  // Effects  : Downloads pdf to user local desktop
  handleExport = () => {
    this.setState({exportButton:"Exporting...",}, function() {

      document.getElementById('exportButton').innerText = "Exporting...";
      if (document.getElementById('svgPanel2').innerHTML == '') {
        createPDF(
          this.getFileName(),
          this.getComments(),
          this.getFilters(),
          this.getMetaData(),
          function() {
            //document.getElementById("exportButton").innerText = "Export: Completed";
            document.getElementById("reportCloseButton").click();
          }
        );
      } else {
        createPDF_comparison(
          this.getFileName(),
          this.getComments(),
          this.getFilters(),
          this.getFilters2(),
          this.getMetaData(),
          function() {
            //document.getElementById("exportButton").innerText = "Export: Completed";
            document.getElementById("reportCloseButton").click();
          }
        );
      }
    });

  };

  handleClick = () => {
    console.log(document.getElementById('svgClone'));
    saveSvgAsPng.saveSvgAsPng(
      document.getElementById('svgClone'),
      'shapes.png'
    );
  };

  // HTML ===============================================================
  // DocString: Renders modal with open and close buttons
  render() {
    const modalStyle = {
      margin: '10 auto',
      transition: 'all .8s',
    };

    const headerStyle = {
      background: 'lightSteelBlue',
    };

    const bodyStyle = {
      background: '#F6F6F6',
    };

    const footerStyle = {
      background: 'White',
    };

    const row = {
      marginLeft: 1,
      marginRight: 1,
    };

    return (
      <div>
        <Button
          className="float-custom"
          id="snapShot"
          onClick={this.handleOpen}
          //rotate={true}
        >
          <FontAwesomeIcon icon={faCamera} className="" />
        </Button>

        

        <Modal id='ReportModal'
          centered
          style={modalStyle}
          className="modal-style"
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
        >
          <Modal.Header style={headerStyle} closeButton>
            <Modal.Title>Report Snapshot</Modal.Title>
          </Modal.Header>

          <Modal.Body
            style={bodyStyle}
            className="modal-content"
            className="show-grid"
          >
            <Row>
              <Col className="col-lg-6 col-md-6 col-sm-12">
                <Row style={row}>
                  <Col id="filterPanel" ref={this.filterPanel}></Col>
                </Row>
                <Row>
                  <Col><h6>Visualisation</h6></Col>
                </Row>
                <Row style={row}>
                  <Col id="svgPanel" ref={this.svgPanel}></Col>
                </Row>
                <Row>
                  <Col><h6 id="comparisonIndicator"></h6></Col>
                </Row>
                <Row style={row}>
                  <Col id="svgPanel2" ref={this.svgPanel2}></Col>
                </Row>
              </Col>

              <Col className="col-lg-6 col-md-6 col-sm-12">
                <Row style={row}>
                  <InputGroup.Text id="basic-addon1" width="100px">
                    <h6>Report File:</h6>
                  </InputGroup.Text>
                </Row>

                <Row style={row}>
                  <InputGroup className="md-12">
                    <FormControl
                      id="fileField"
                      ref={this.fileField}
                      type="text"
                      value={this.state.fileFieldValue}
                      onChange={this.handleFileNameChange}
                      placeholder={this.state.fileFieldHolder}
                      aria-label=""
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </Row>

                <Editor
                  ref={this.commentField}
                  apiKey="yggckjsj6mcv7t6b0991e0e0azj37r7scnpdunfbt1hv55aw"
                  init={{
                    placeholder: 'Write here...',
                    width: '100%',
                    height: '20rem',
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image',
                      'charmap print preview anchor help',
                      'searchreplace visualblocks code',
                      'insertdatetime media table paste wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic underline superscript subscript| \
                                                forecolor | fonts | link | fonts | \
                                                bullist numlist outdent indent | help',
                  }}
                  onChange={this.handleEditorChange}
                />
                <Row style={row}>
                  <Form.Group
                    controlId="exampleForm.ControlTextarea1"
                    className="md-12 comment-box"
                  ></Form.Group>
                </Row>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer style={footerStyle}>
            <Button id="reportCloseButton" variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button id = "exportButton" variant="primary" onClick={this.handleExport}>
                {this.state.exportButton}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
