import React from 'react';
import DatasetFilter from '../dataset-filter';
import { Col } from 'react-bootstrap';
import MultiSelect from 'react-multi-select-component';

const overrideStrings = {
  selectSomeItems: 'Gender',
  allItemsAreSelected: 'All selected.',
  selectAll: 'Select All',
  search: 'Search',
};

export default class Gender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      toggleSelectFilter:'multi-select'
    };
    this.dimension = props.crossfilter.dimension(
      (d) => d.metaData.patientInfo.gender
    );
    this.initOptions();
    this.props.addDimension(this.dimension);
  }

  componentDidUpdate() {
    if (this.props.reset === true && this.state.selected !== []) {
      this.onFilterChange([]);
      this.setState({toggleSelectFilter:'multi-select'})
    }
  }

  onFilterChange = (val) => {
    this.setState({
      selected: val,
      toggleSelectFilter:'multi-select selectionGender'
    });

    // Clear all previous filters
    this.dimension.filterAll();

    if (val.length !== 0) {
      this.dimension.filterFunction(
        DatasetFilter.multivalue_filter(val.map((d) => d.value))
      );
    }
    console.log("gender val", val);
    if (val.length == 0) {
      this.setState({
      toggleSelectFilter:'multi-select'
      });
      }
    this.props.onFilterChange();
  };

  initOptions = () => {
    let group = this.dimension.group();

    this.options = group.all().map((d) => {
      return {
        label: d.key,
        value: d.key,
      };
    });
  };

  render() {
    return (
      <Col
        md={this.props.md}
        sm={this.props.sm}
        id={`genderRange-${this.props.id}`}
      >
        <p className="filter-label">Gender</p>
        <MultiSelect
          options={this.options}
          className={this.state.toggleSelectFilter}
          value={this.state.selected}
          onChange={this.onFilterChange}
          overrideStrings={overrideStrings}
        />
      </Col>
    );
  }
}
