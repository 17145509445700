import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Card } from 'react-bootstrap';

export default function BannerCard(props) {
        
  return (
    
    <Col  xs={12}  sm={2} md={2} lg={`${props.data.containersize}`} className="px-0 overview-padding  pr-0 pl-0">
      <Card className={`banner-card ${props.data.class}`}>
        <Card.Body>
          <Card.Title className="mb-0">
            <FontAwesomeIcon icon={props.data.icon} className="mr-2" />
            <span>{props.data.name}</span>
          </Card.Title>
          <Card.Text>{props.data.content}</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
}