import React, { Suspense, lazy, PureComponent } from 'react';
import axios from 'axios';
import { Container, Row } from 'react-bootstrap';

import Banner from './banner';
import DatasetFilter from './dataset-filter';
// import Visualization from './visualization';
import Conversations from './conversations';

import crossfilter from 'crossfilter2';

import '../assets/css/body.css';
import ReportModal from './reportModal';
import QuickScan from './quickScan';
import * as d3 from 'd3';
// import Modal from 'react-bootstrap/Modal'
// import ButtonGroup from 'react-bootstrap/ButtonGroup'
// import Button from 'react-bootstrap/Button'
// import Form from 'react-bootstrap/Form'
// import Col from 'react-bootstrap/Col'
import UpdateUserTopic from './update-user-topic';
// import PredictiveModelVis from './predictive-model';

const Visualization = lazy(() => import('./visualization'));
const PredictiveModelVis = lazy(() => import('./predictive-model'));

//const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = 'https://beta.convis.statlabs.io'; // for dev env
const BASE_URL = 'http://localhost:86'; // for local dev env

class VisualizationOptimized extends PureComponent {
  shouldComponentUpdate(nextProps) {
    console.log("next props data",nextProps.data.nodes);
    console.log("current props data",this.props.data.nodes);
    const currentNodes = JSON.stringify(this.props.data.nodes);
    const nextNodes = JSON.stringify(nextProps.data.nodes);
    console.log("not same?", currentNodes != nextNodes);
    return currentNodes != nextNodes;
    // return false;
  }

  render() {
    const { data, crossfilter, id, comparisonOn, userRole, cfUpdated, userTopicUpdated, setUserTopicUpdated, onFilterChange, visibility } = this.props;
    return (
      <Visualization
        data={data}
        crossfilter={crossfilter}
        id={id}
        comparisonOn={comparisonOn}
        userRole={userRole}
        cfUpdated={cfUpdated}
        userTopicUpdated={userTopicUpdated}
        setUserTopicUpdated={setUserTopicUpdated}
        onFilterChange={onFilterChange}
        visibility={visibility}
      />
    );
  }
}

export default class Body extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: this.props.data,
      dataset_id: this.props.dataset_id,
      user: this.props.user,
      cfUpdated: false,
      cfUpdated2: false,
      comparisonOn: false,
      updateUserDefinedTopicSubmitButton: 'Submit',
      userTopicUpdated: false,
      userTopicUpdated2: false,
      buttons: null,
      error: '',
      conversationExplorerDisplayed: true,
      conversationExplorerDisplayed2: true,
      selectedTopicsCount: 0,
      selectedTopicsCount2: 0,
      totalTopicsCount: 0,
      totalTopicsCount2: 0,
      loadingFinished: false,
    };
    this.crossfilter1 = crossfilter(this.props.data.nodes.conversations);
    this.crossfilter2 = crossfilter(this.props.data.nodes.conversations);
  }

  // DocString: Key shortcut(F8) - opens modal
  addShortCutUpdateUserDefinedTopics = () => {
    document.onkeyup = function (e) {
      if (e.which == 119) {
        document.getElementById('updateUserDefinedTopic').click();
        // console.log("F8 key was pressed");
      } else {
      }
    };
  };

  componentDidMount() {
    this.addShortCutUpdateUserDefinedTopics();
    this.setState({
      loadingFinished: true
    });
  }

  updateCrossfilter = () => {
    this.setState({
      cfUpdated: true
    });
  };

  resetCrossfilter = () => {
    this.setState({
      cfUpdated: false
    });
  };

  updateCrossfilter2 = () => {
    this.setState({
      cfUpdated2: true
    });
  };

  resetCrossfilter2 = () => {
    this.setState({
      cfUpdated2: false
    });
  };

  setComparison = (bool) => {
    this.setState({
      comparisonOn: bool,
    });
  };

  setSelectedTopicsCount = (val) => {
    this.setState({
      selectedTopicsCount: val
    })
  }

  setTotalTopicsCount = (val) => {
    this.setState({
      totalTopicsCount: val
    })
  }

  setSelectedTopicsCount2 = (val) => {
    this.setState({
      selectedTopicsCount2: val
    })
  }

  setTotalTopicsCount2 = (val) => {
    this.setState({
      totalTopicsCount2: val
    })
  }

  // getUserTopicUpdated = () => {
  //   var userTopicUpdated = localStorage.getItem( 'userTopicUpdated' );
  //   return userTopicUpdated;
  // }

  setUserTopicUpdated = (bool) => {
    localStorage.setItem('userTopicUpdated', bool);
    this.setState({
      userTopicUpdated: bool
    });
  }

  setUserTopicUpdated2 = (bool) => {
    localStorage.setItem('userTopicUpdated2', bool);
    this.setState({
      userTopicUpdated2: bool
    });
  }

  preRenderComparison = () => {
    setTimeout(() => {
      this.setState({
        comparisonReady: true,
      });
    }, 0);
  }
  // Set topic / subtopic level sentiment
  // addSentimentWidth(data) {
  //   // Topic Map
  //   let mergedTopics = [];
  //   for (const [k, v] of Object.entries(data.nodes.topics)) {
  //     mergedTopics = [...mergedTopics, ...v];
  //   }
  //   mergedTopics.forEach((topic) => {
  //     topic.sentWidth = [0, 0, 0, 0, 0];
  //     topic.numConv = 0;
  //   });
  //   let topicById = d3.map(mergedTopics, (d) => d.id);

  //   // Subtopic Map
  //   let mergedSubtopics = [];
  //   for (const [k, v] of Object.entries(data.nodes.subtopics)) {
  //     mergedSubtopics = [...mergedSubtopics, ...v];
  //   }
  //   mergedSubtopics.forEach((subtopic) => {
  //     subtopic.sentWidth = [0, 0, 0, 0, 0];
  //     subtopic.numConv = 0;
  //   });
  //   let subtopicById = d3.map(mergedSubtopics, (d) => d.id);

  //   // Updating the sentiments
  //   data.nodes.conversations.forEach((conv) => {
  //     for (const [k, v] of Object.entries(conv.conversationTopics)) {
  //       v.forEach((subtopicId) => {
  //         let subtopic = subtopicById.get(subtopicId);
  //         subtopic.sentWidth.forEach((d, i) => {
  //           subtopic.sentWidth[i] += conv.metaData.sentWidth[i];
  //         });
  //         subtopic.numConv++;
  //       });
  //     }
  //   });
  //   // Normalizing the sentiments
  //   mergedSubtopics.forEach((subtopic) => {
  //     subtopic.sentWidth.forEach((d, i) => {
  //       if (subtopic.numConv !== 0) subtopic.sentWidth[i] /= subtopic.numConv;
  //     });

  //     subtopic.topic.forEach((d) => {
  //       let topic = topicById.get(d);
  //       topic.sentWidth.forEach((d, i) => {
  //         topic.sentWidth[i] += subtopic.sentWidth[i] * subtopic.numConv;
  //       });
  //       topic.numConv += subtopic.numConv;
  //     });
  //   });

  //   mergedTopics.forEach((topic) => {
  //     topic.sentWidth.forEach((d, i) => {
  //       if (topic.numConv !== 0) topic.sentWidth[i] /= topic.numConv;
  //     });
  //   });
  // }

  addSentimentWidth(data) {
    const worker = new Worker('./dataWorker.js');
    worker.postMessage(data.nodes.conversations);
    worker.onmessage = (e) => {
      const processedData = e.data;
      this.setState({ processedData });
      worker.terminate();
    };

    const cache = {};

    // If data has not changed, return cached result
    const key = JSON.stringify(data.nodes.conversations);
    if (cache[key]) return cache[key];

    let mergedTopics = [];
    for (const [k, v] of Object.entries(data.nodes.topics)) {
      mergedTopics = [...mergedTopics, ...v];
    }
    mergedTopics.forEach((topic) => {
      topic.sentWidth = [0, 0, 0, 0, 0];
      topic.numConv = 0;
    });
    let topicById = d3.map(mergedTopics, (d) => d.id);

    let mergedSubtopics = [];
    for (const [k, v] of Object.entries(data.nodes.subtopics)) {
      mergedSubtopics = [...mergedSubtopics, ...v];
    }
    mergedSubtopics.forEach((subtopic) => {
      subtopic.sentWidth = [0, 0, 0, 0, 0];
      subtopic.numConv = 0;
    });
    let subtopicById = d3.map(mergedSubtopics, (d) => d.id);

    data.nodes.conversations.forEach((conv) => {
      for (const [k, v] of Object.entries(conv.conversationTopics)) {
        v.forEach((subtopicId) => {
          let subtopic = subtopicById.get(subtopicId);
          subtopic.sentWidth.forEach((d, i) => {
            subtopic.sentWidth[i] += conv.metaData.sentWidth[i];
          });
          subtopic.numConv++;
        });
      }
    });

    mergedSubtopics.forEach((subtopic) => {
      subtopic.sentWidth.forEach((d, i) => {
        if (subtopic.numConv !== 0) subtopic.sentWidth[i] /= subtopic.numConv;
      });

      subtopic.topic.forEach((d) => {
        let topic = topicById.get(d);
        topic.sentWidth.forEach((d, i) => {
          topic.sentWidth[i] += subtopic.sentWidth[i] * subtopic.numConv;
        });
        topic.numConv += subtopic.numConv;
      });
    });

    mergedTopics.forEach((topic) => {
      topic.sentWidth.forEach((d, i) => {
        if (topic.numConv !== 0) topic.sentWidth[i] /= topic.numConv;
      });
    });

    cache[key] = data;
    return data;
  }



  handleVisulizationSwitch = (val) => {
    this.setState({
      conversationExplorerDisplayed: val
    })
  }

  handleVisulizationSwitch2 = (val) => {
    this.setState({
      conversationExplorerDisplayed2: val
    })
  }

  render() {
    const userRole = this.state.user.role;

    const modalStyle = {
      margin: '10 auto',
      transition: 'all .8s',
    };

    const headerStyle = {
      background: 'lightSteelBlue',
    };

    const bodyStyle = {
      background: '#F6F6F6',
    };

    let updatedConversations1 = this.crossfilter1.allFiltered();
    let updatedData1 = {
      nodes: {
        ...this.props.data.nodes,
        conversations: updatedConversations1,
      },
      links: this.props.data.links,
    };
    console.log("adding sent width")
    this.addSentimentWidth(updatedData1);

    let updatedConversations2, updatedData2, datasetFilter;
    let subtopics = JSON.parse(JSON.stringify(this.props.data.nodes.subtopics));
    let topics = JSON.parse(JSON.stringify(this.props.data.nodes.topics));
    // if (this.state.comparisonOn) {
      updatedConversations2 = this.crossfilter2.allFiltered();
      updatedData2 = {
        nodes: {
          ...this.props.data.nodes,
          topics: topics,
          subtopics: subtopics,
          conversations: updatedConversations2,
        },
        links: this.props.data.links,
      };
      this.addSentimentWidth(updatedData2);
      datasetFilter = (
        <DatasetFilter
          data={this.props.data}
          crossfilter={this.crossfilter2}
          onFilterChange={this.updateCrossfilter2}
          setComparison={this.setComparison}
          hasButton={true}
          isComparison={true}
          id={2}
          onVisulizationChange={this.handleVisulizationSwitch2} //new
          conversationExplorerDisplayed={this.state.conversationExplorerDisplayed2}
          selectedTopicsCount={this.state.selectedTopicsCount2}
          totalTopicsCount={this.state.totalTopicsCount2}
        />
      );
      let visualization = (visible) => (
        <Suspense fallback={<div>Loading Comparison Visualization...</div>}>
          <Row className="visualization-row m-0" style={{ visibility: visible ? 'visible' : 'hidden' }}>
            <VisualizationOptimized
              data={updatedData2}
              crossfilter={this.crossfilter2}
              id={2}
              comparisonOn={this.state.comparisonOn}
              userRole={userRole}
              cfUpdated={this.state.cfUpdated2}
              userTopicUpdated={this.state.userTopicUpdated2}
              setUserTopicUpdated={this.setUserTopicUpdated2}
              onFilterChange={this.resetCrossfilter2}
              visibility={visible}
            />
            <Conversations comparisonOn={this.state.comparisonOn} id={2} convVisible={true} />
            {/* {userRole == 'user' ? 
          // (<Conversations comparisonOn={this.state.comparisonOn} id={2} convVisible={false}/>)
          null
           : (
            <Conversations comparisonOn={this.state.comparisonOn} id={2} convVisible={true}/>
          )} */}
          </Row>
        </Suspense>
      );
    // };

    let visualizationSet = (visible) => {
      if (this.state.conversationExplorerDisplayed) {
        return (
          <Suspense fallback={<div>Loading Visualization...</div>}>
            <Row className="visualization-row m-0 mb-2">
              <VisualizationOptimized
                data={updatedData1}
                crossfilter={this.crossfilter1}
                id={1}
                comparisonOn={this.state.comparisonOn}
                userRole={userRole}
                cfUpdated={this.state.cfUpdated}
                userTopicUpdated={this.state.userTopicUpdated}
                setUserTopicUpdated={this.setUserTopicUpdated}
                onFilterChange={this.resetCrossfilter}
                visibility={visible}
              />
              {userRole == 'user' ? null : (
                this.state.conversationExplorerDisplayed ? <Conversations comparisonOn={this.state.comparisonOn} id={1} />
                  : null
              )}
            </Row>
          </Suspense>
        )
      } else {
        return (
          <div>
            <Suspense fallback={<div>Loading Visualization...</div>}>
              <PredictiveModelVis
                dataset_id={this.props.dataset_id}
                setSelectedTopicsCount={this.setSelectedTopicsCount}
                setTotalTopicsCount={this.setTotalTopicsCount}
              />
              {userRole == 'user' ? null : (
                this.state.conversationExplorerDisplayed ? <Conversations comparisonOn={this.state.comparisonOn} id={1} />
                  : null
              )}
            </Suspense>
          </ div>
        )
      }
    }
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    return (
      <>
        <Container fluid className="convex-container pb-3">
          <Banner data={this.props.data}
            conversationExplorerDisplayed={this.state.conversationExplorerDisplayed}
          />
          <DatasetFilter
            data={this.props.data}
            crossfilter={this.crossfilter1}
            onFilterChange={this.updateCrossfilter}
            setComparison={this.setComparison}
            hasButton={true}
            isComparison={false}
            id={1}
            onVisulizationChange={this.handleVisulizationSwitch} //new
            conversationExplorerDisplayed={this.state.conversationExplorerDisplayed}
            selectedTopicsCount={this.state.selectedTopicsCount}
            totalTopicsCount={this.state.totalTopicsCount}
          />
          {this.state.comparisonOn ? datasetFilter : null}

          {visualizationSet(true)}
          {/* <Row className="visualization-row m-0 mb-2">
            {
              this.state.conversationExplorerDisplayed
              ?
              (<Visualization
              data={updatedData1}
              crossfilter={this.crossfilter1}
              id={1}
              comparisonOn={this.state.comparisonOn}
              userRole={userRole}
              cfUpdated={this.state.cfUpdated}
              userTopicUpdated={this.state.userTopicUpdated}
              setUserTopicUpdated={this.setUserTopicUpdated}
              onFilterChange={this.resetCrossfilter}
              />)
              : <PredictiveModelVis dataset_id={this.props.dataset_id}/>
            }

            {userRole == 'user' ? null : (
              this.state.conversationExplorerDisplayed ? <Conversations comparisonOn={this.state.comparisonOn} id={1} />
                                                       : null 
            )}
          </Row> */}
          {this.state.comparisonOn? visualization(this.state.comparisonOn):null}
          <ReportModal />
          <QuickScan dataset_id={this.state.dataset_id} />
          <UpdateUserTopic
            dataset_id={this.state.dataset_id}
            data={this.props.data}
            loadDataset={this.props.loadDataset}
            setUserTopicUpdated={this.setUserTopicUpdated}
          />
        </Container>
        <footer className="p-3" style={{ borderTop: '1px solid #d6d3d3' }}>
          <p className="m-0" style={{ fontSize: '0.8rem', color: '#576777' }}>
            Copyright © {currentYear} UBC mHealth ConVISation Labs
          </p>
        </footer>
      </>
    );
  }
}