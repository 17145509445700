import React from 'react';

import axios from 'axios'
import { Container, Row } from 'react-bootstrap'

import '../assets/css/body.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Body from './body'

// const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL = process.env.REACT_APP_CONVIS_URL;
// const BASE_URL = 'https://beta.convis.statlabs.io'; // for dev env
// const BASE_URL = 'http://localhost:86'; // for local dev env

export default class UpdateUserTopic extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleOpenUDT = this.handleOpenUDT.bind();
    this.handleCloseUDT = this.handleCloseUDT.bind();
    this.state = {
      show: false,
      dataset_id: this.props.dataset_id,
      newUserDefinedTopic: this.props.data.nodes.topics.userDefined.map((el) => { return el.name }),
      updateUserDefinedTopicSubmitButton: 'Submit',
      addNewTopic: ''
    };
  }

  // DocString: Closes window
  handleCloseUDT = () => {
    this.setState({ show: false });
  };

  // DocString: Opens window
  handleOpenUDT = () => {
    this.setState({ 
      show: true,
      newUserDefinedTopic: this.props.data.nodes.topics.userDefined.map((el) => { return el.name })
     });
  };

  handleAddUserDefinedTopic = (event) => {
    this.setState({addNewTopic: event.target.value});
  }


  handleAddUserDefinedTopicSubmit = () => {
    let updateData = this.state.newUserDefinedTopic
    if (this.state.addNewTopic) {
      if (!updateData.includes(this.state.addNewTopic)) {
        updateData.push(this.state.addNewTopic)
      }
      this.setState({ 
        newUserDefinedTopic: updateData,
        addNewTopic: '' });
    }
  }

  undoAddButton = (e) => {
    let value = e.target.value || e.target.getAttribute('data-value')

    let updateData = this.state.newUserDefinedTopic
    if (updateData.includes(value)) {
      updateData = updateData.filter(s => s !== value)
    }
    this.setState({ newUserDefinedTopic: updateData});
  }

  updateUserDefinedTopic = () => {
    this.setState({ updateUserDefinedTopicSubmitButton: 'In Progress...' });

    const req = {
      user_topics: this.state.newUserDefinedTopic,
      dataset_id: this.state.dataset_id
    };

    axios
      .post(BASE_URL + '/api/nlp_explorer/update_user_topics', JSON.stringify(req), {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((result) => {
        if (result.status === 200) {
          this.setState({ updateUserDefinedTopicSubmitButton: 'Submit' });
          this.props.setUserTopicUpdated(true);

          const req = {
            dataset_id: this.state.dataset_id,
          };
          // this.props.loadDataset(req);
          window.location.reload()
        }
      })
      .catch((e) => {
        console.log(e.response);
        if (e.response) {
          if (e.response.status === 400) {
            this.setState({
              error: e.response,
              updateUserDefinedTopicSubmitButton: 'Submit'
            });
          }
        }
      });
  }
        
  render() {
    const modalStyle = {
      margin: '10 auto',
      transition: 'all .8s',
    };

    const headerStyle = {
      background: 'lightSteelBlue',
    };

    const bodyStyle = {
      background: '#F6F6F6',
    };

    return (
      <>
      <Button
            id="updateUserDefinedTopic"
            onClick={this.handleOpenUDT}
            //rotate={true}
          >
      </Button>

      <Modal id="addUserDefinedTopicModal" className="modal-style" show={this.state.show}
      onHide={this.handleCloseUDT}>
        <Modal.Header style={headerStyle} closeButton>
          <Modal.Title>Update User Defined Topics</Modal.Title>
        </Modal.Header>
        <Modal.Body style={bodyStyle} className="modal-content">
          <Form>
            <Form.Group as={Row} controlId="formPlaintextAdd">
              <Col sm="10">
                <Form.Control 
                  type="text" 
                  placeholder="Add:" 
                  value={this.state.addNewTopic}
                  onChange={this.handleAddUserDefinedTopic}
                />
              </Col>
              <Col xs="auto" className="my-1">
                <Button id="addUserDefinedTopic" style={{minWidth: '45px'}} onClick={this.handleAddUserDefinedTopicSubmit}>+</Button>
              </Col>
            </Form.Group>
          </Form>
          <>
            {this.state.newUserDefinedTopic.map((word) => {
              return <Button style={{padding: '3px 3px', width:'fit-content',marginLeft:'25px',marginBottom:'5px'}} className='udbuttonlist' variant="outline-secondary" onClick={this.undoAddButton} value={word}>
                    <i className="fa fa-minus-circle" style={{padding: '3px', color: 'red', alignSelf: 'start', left: '4%', position: 'absolute'}} onClick={this.undoAddButton} data-value={word}></i>
                    {word}
                </Button>
            })}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseUDT}>
            Close
          </Button>
          <Button variant="primary" onClick={this.updateUserDefinedTopic}>
            {this.state.updateUserDefinedTopicSubmitButton}
          </Button>
        </Modal.Footer>
      </Modal>
      </>
    )
  };
}