/* 
ConViScope Report Saving Feature
Export & comment functions
*/

import jsPDF from 'jspdf';
import {svgAsPngUri} from 'save-svg-as-png';
import ReportModal from '../reportModal';

// REFACTORING WIP ===================================================================================

// DocString: Adds a fileName,metaData,filters,comments sheet to the pdf doc
// Returns  : Doc with added comments sheet
function addInfoSheet(doc,fileName,metaData,filters,comments) {
    doc.addPage('a4','portrait');

    doc.fromHTML("<h6>"+fileName+"</h6>",15,10);
    doc.fromHTML("<h3>DATASET BANNER INFO:</h3>",15,24);
    doc.rect(15, 35, 180, 30);
    doc.fromHTML("<h4>"+metaData+"</h4>",20,14);
    
    doc.fromHTML("<h3>DATASET FILTERS:</h3>",15,64);
    doc.rect(15, 75, 180, 25);
    doc.fromHTML("<h4>"+filters+"</h4>",20,59);

    doc = createComments(doc,comments,false);
    doc = createPageNumber(doc,2);

    return doc;
}

// DocString: Adds a fileName,metaData,filters,comments sheet to the pdf doc
//          : Adds comparison filters as well
// Returns  : Doc with added comments sheet
function addInfoSheet2(doc,fileName,metaData,filters1,filters2,comments) {
    doc.addPage('a4','portrait');

    doc.fromHTML("<h6>"+fileName+"</h6>",15,10);
    doc.fromHTML("<h3>DATASET BANNER INFO:</h3>",15,24);
    doc.rect(15, 35, 180, 30);
    doc.fromHTML("<h4>"+metaData+"</h4>",20,14);
    
    doc.fromHTML("<h3>DATASET FILTERS 1:</h3>",15,64);
    doc.rect(15, 75, 180, 25);
    doc.fromHTML("<h4>"+filters1+"</h4>",20,59);

    doc.fromHTML("<h3>DATASET FILTERS 2:</h3>",15,99);
    doc.rect(15, 110, 180, 25);
    doc.fromHTML("<h4>"+filters2+"</h4>",20,94);

    doc = createComments(doc,comments,true);
    doc = createPageNumber(doc,3);

    return doc;
}

// DocString: Adds svg as an image
function createSVG(doc,uri,pageNum) {
    doc.addImage(uri,"PNG",11,20,doc.internal.pageSize.getWidth()-20,doc.internal.pageSize.getHeight()-65);
    doc.fromHTML("<h8>Copyright © 2020 UBC mHealth ConVISation Labs</h8>",doc.internal.pageSize.getWidth()/2 - 49,doc.internal.pageSize.getHeight()-25);
    doc = createPageNumber(doc,pageNum);
    return doc;
}

// DocString: Adds comments to doc, then returns new doc 
function createComments(doc,comments,hasComparison) {
    if (comments == "") {
        // Add nothing
    } else if (!hasComparison) {
        doc.fromHTML("<h3>COMMENTS AND NOTES:</h3>",15,100,{'width':180});
        doc.fromHTML(comments,
            15,
            110,
            {'width':180});
    } else {
        doc.fromHTML("<h3>COMMENTS AND NOTES:</h3>",15,135,{'width':180});
        doc.fromHTML(comments,
            15,
            145,
            {'width':180});
    }
    return doc;
}

// DocString: Adds page number
function createPageNumber(doc,pageNum) {
    doc.fromHTML("<h4>"+pageNum+"</h4>",doc.internal.pageSize.getWidth()-15,doc.internal.pageSize.getHeight()-28);
    return doc;
}

// ===============================================================================================

// DocString: Consolidates filters, comments, svg to a pdf file
// Effects  : Downloads a pdf file to user desktop
export function createPDF(fileName,comments,filters,metaData,callback) {
    var doc = new jsPDF('landscape');

    var svg_raw = document.getElementById('svgPanel');
    svgAsPngUri(svg_raw.childNodes[0]).then(uri => {
        //doc.fromHTML("<h4>Dashboard Component</h4>",15,10,{'width':180});

        doc = createSVG(doc,uri,1);
        doc = addInfoSheet(doc,fileName,metaData,filters,comments);
        doc.save(fileName);
    }).then( doc => {
        callback();
    }
    )
}

export function createPDF_comparison(fileName,comments,filters1,filters2,metaData,callback) {
    var doc = new jsPDF('landscape');

    var svg1 = document.getElementById('svgPanel');
    var svg2 = document.getElementById('svgPanel2');

    svgAsPngUri(svg1.childNodes[0]).then(uri => {
        doc = createSVG(doc,uri,1);

    }).then(doc,svgAsPngUri(svg2.childNodes[0]).then(uri => {
        doc.addPage('a4', 'landscape');

        doc = createSVG(doc,uri,2);
        doc = addInfoSheet2(doc,fileName,metaData,filters1,filters2,comments);

        doc.save(fileName);
    })).then( doc => {
        callback();
    });
    
}

