import { useEffect, useState } from "react";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const SessionManager = () => {
  const [lastActivity, setLastActivity] = useState(Date.now());

  const updateActivity = () => setLastActivity(Date.now());

  useEffect(() => {
    document.addEventListener("mousemove", updateActivity);
    document.addEventListener("keydown", updateActivity);

    const checkSession = setInterval(async () => {
      const now = Date.now();
      if (now - lastActivity > 3600000) {
        document.cookie = "access_token=; Max-Age=0";
        window.location.href = process.env.REACT_APP_CONVIS_URL;
      } else {
        try{
            const apiUrl = BASE_URL+'/api/protected';
            console.log("Checking session", apiUrl);
            await fetch(apiUrl, { method: "GET", credentials: "include" }); // Keep session alive
        }
        catch(err){
            console.log(err);
        }
      }
    }, 300000);
    return () => {
      document.removeEventListener("mousemove", updateActivity);
      document.removeEventListener("keydown", updateActivity);
      clearInterval(checkSession);
    };
  }, [lastActivity]);

  return null;
};

export default SessionManager;
