import React from 'react';
import BannerCard from './banner-card';

import logo from '../assets/images/conviscope-logo.jpg';
import '../assets/css/banner.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDatabase,
  faHospital,
  faCalendar,
  faStethoscope,
  faProcedures,
  faSms,
  faLightbulb,
  faCamera,
  faEdit,
  faHistory,
  faQuestionCircle,
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Modal, Card, CardDeck, DropdownButton, Dropdown, Button, ButtonGroup } from 'react-bootstrap';
import FaqPageContent from './faq-components/faq-tab-content';

export default class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      showFaqModal: false,
    };

  }

  countPatient = (conversations) => {
    let patientIDs = new Set();
    let count = 0;

    for (let conv of conversations) {
      let patientID = conv['metaData']['patientInfo']['patientID'];
      if (!patientIDs.has(patientID)) {
        count++;
        patientIDs.add(patientID);
      }
    }
    return count;
  };

  countProvider = (conversations) => {
    let providerIDs = new Set();
    let count = 0;

    for (let conv of conversations) {
      let providerID = conv['metaData']['patientInfo']['healthCareProvider'];
      if (!providerIDs.has(providerID) && providerID !== -1) {
        count++;
        providerIDs.add(providerID);
      }
    }
    return count;
  };

  toggleFaqModal = () => {
    this.setState((prevState) => ({ showFaqModal: !prevState.showFaqModal }));
  };

  render() {
    // const CONVIS_URL = process.env.REACT_APP_CONVIS_URL;
    const CONVIS_URL = "https://dev.convis.statlabs.io";  // for staging env

    let nodes = this.state.data['nodes'];
    let conversations = nodes['conversations'];
    console.log("nodes", nodes);
    console.log("conversations", conversations);

    let datasetStartDate = conversations[0]['conversationDate'][
      'startDate'
    ].slice(0, 10);
    let datasetEndDate = conversations[conversations.length - 1][
      'conversationDate'
    ]['endDate'].slice(0, 10);

    // a and b are javascript Date objects
    function dateDiff(a, b) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      let totalDays = Math.floor((utc2 - utc1) / _MS_PER_DAY);

      let years = Math.floor(totalDays / 365);
      let months = Math.floor((totalDays % 365) / 30);
      let days = Math.floor((totalDays % 365) % 30);

      let dateStr = '';
      if (years > 0) dateStr += `${years} year `;
      if (years > 0 || months > 0) dateStr += `${months} month `;
      else dateStr += `${days} days`;

      return dateStr;
    }
    function reportmodalpopup() {
      document.getElementById('snapShot').click();
    }

    function overviewmodalpopup() {
      document.getElementById('snapShotoverview').click();
    }

    function userdefinedpopup() {
      document.getElementById('updateUserDefinedTopic').click();
    }

    function faqpagepop() {
      window.open('/FaqPage', "_blank")
    }

    function backWindowOption() {
      window.history.back();
    }

    const customStyles = {
      maxHeight: '100vh',
      margin: '10 auto',
    };

    const METADATA = [
      {
        icon: faDatabase,
        class: 'non-numeric',
        name: 'Dataset',
        content: nodes['datasetTitle'],
        containersize: '2'
      },
      {
        icon: faHospital,
        class: 'non-numeric',
        name: 'Health Center',
        content: nodes['datasetHealthCentre'],
        containersize: '2'
      },
      {
        icon: faCalendar,
        class: 'date',
        name: 'Date',
        content:
          datasetStartDate +
          ' ~ ' +
          datasetEndDate +
          `\n ( ${dateDiff(
            new Date(datasetStartDate),
            new Date(datasetEndDate)
          )} )`,
        containersize: '2'
      },
      {
        icon: faStethoscope,
        class: 'numeric',
        name: 'Providers',
        content: this.countProvider(conversations).toString(),
        containersize: '2'

      },
      {
        icon: faProcedures,
        class: 'numeric',
        name: 'Patients',
        content: this.countPatient(conversations).toString(),
        containersize: '2'
      },
      {
        icon: faSms,
        class: 'numeric',
        name: 'Conversations',
        content: conversations.length.toString(),
        containersize: '2'
      },
    ];

    let col = [];
    METADATA.forEach((d) => {
      col.push(<BannerCard data={d} key={d.name} />);
    });

    let dropDownItemClassName = this.props.conversationExplorerDisplayed ? "dropdown-item"
      : "dropdown-item disabled";

    return (
      <Row className="banner-row align-items-center">
        <Col xs={12} sm={2} md={2} lg={2} className="overview-padding  pr-0 pl-0">
          <Dropdown as={ButtonGroup} style={{paddingRight: '1vw', marginBottom: '1vh'}}>
            <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" style={{margin:'auto'}}>
              <FontAwesomeIcon icon={faBars} /> 
            </Dropdown.Toggle>

            <Dropdown.Menu >
              <Dropdown.Item onClick={overviewmodalpopup} ><FontAwesomeIcon icon={faLightbulb} className='dropdownFaIcon'></FontAwesomeIcon><span className="dropdwontext" style={{ marginLeft: '6px' }}>Snapshot</span></Dropdown.Item>
              <Dropdown.Item onClick={reportmodalpopup} className={dropDownItemClassName}><FontAwesomeIcon icon={faCamera} className='dropdownFaIcon'></FontAwesomeIcon><span className="dropdwontext" style={{ marginLeft: '2px' }}>Report</span></Dropdown.Item>
              <Dropdown.Item onClick={userdefinedpopup} className={dropDownItemClassName}><FontAwesomeIcon icon={faEdit} className='dropdownFaIcon'></FontAwesomeIcon><span className="dropdwontext" style={{ marginLeft: '2px' }}>User Defined Topics</span></Dropdown.Item>
              <Dropdown.Item onClick={this.toggleFaqModal} ><FontAwesomeIcon icon={faQuestionCircle} className='dropdownFaIcon'></FontAwesomeIcon><span className="dropdwontext" style={{ marginLeft: '2px' }}>FAQs</span></Dropdown.Item>
              <Dropdown.Item onClick={backWindowOption} ><FontAwesomeIcon icon={faHistory} className='dropdownFaIcon'></FontAwesomeIcon><span className="dropdwontext" style={{ marginLeft: '3px' }}>Return to Datasets</span></Dropdown.Item>
            </Dropdown.Menu>
            <img
              src={logo}
              className="img-fluid rounded logo-main"
              alt="Conviscope Logo"
              onClick={() => {
                window.history.back();
              }}
              style={{ cursor: 'pointer' }
              }
            ></img>

            {/* <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" ></Dropdown.Toggle>

            <Dropdown.Menu >
              <Dropdown.Item onClick={overviewmodalpopup} ><FontAwesomeIcon icon={faLightbulb} className='dropdownFaIcon'></FontAwesomeIcon><span className="dropdwontext" style={{ marginLeft: '6px' }}>Snapshot</span></Dropdown.Item>
              <Dropdown.Item onClick={reportmodalpopup} className={dropDownItemClassName}><FontAwesomeIcon icon={faCamera} className='dropdownFaIcon'></FontAwesomeIcon><span className="dropdwontext" style={{ marginLeft: '2px' }}>Report</span></Dropdown.Item>
              <Dropdown.Item onClick={userdefinedpopup} className={dropDownItemClassName}><FontAwesomeIcon icon={faEdit} className='dropdownFaIcon'></FontAwesomeIcon><span className="dropdwontext" style={{ marginLeft: '2px' }}>User Defined Topics</span></Dropdown.Item>
              <Dropdown.Item onClick={this.toggleFaqModal} ><FontAwesomeIcon icon={faQuestionCircle} className='dropdownFaIcon'></FontAwesomeIcon><span className="dropdwontext" style={{ marginLeft: '2px' }}>FAQs</span></Dropdown.Item>
              <Dropdown.Item onClick={backWindowOption} ><FontAwesomeIcon icon={faHistory} className='dropdownFaIcon'></FontAwesomeIcon><span className="dropdwontext" style={{ marginLeft: '3px' }}>Return to Datasets</span></Dropdown.Item>
            </Dropdown.Menu> */}
          </Dropdown>
          {/* <img
            src={logo}
            className="img-fluid rounded logo-main"
            alt="Conviscope Logo"
            onClick={() => {
              window.history.back();
            }}
            style={{ cursor: 'pointer' }
          }
          ></img> */}
        </Col>
        <Col>
          <Row className="overview-row" id="bannerElement">

            {col}
            {/* </Row>
          <Row> */}

          </Row>
        </Col>
        <Modal show={this.state.showFaqModal} onHide={this.toggleFaqModal} size="lg" centered className="modal-style" style={customStyles}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="modal-content show-grid" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
            <FaqPageContent />
            <Button variant="secondary" style={{ zIndex: 1}} onClick={this.toggleFaqModal}>
              Close
            </Button>
          </Modal.Body>
        </Modal>

        {/* <Col xs={12}  sm={1} md={1} lg={1} className="overview-padding  pr-0 pl-0">
            <Card className="border-left-overview">
              <Card.Body>
              <Card.Subtitle className="no-margin  lightbulbicon">
                <FontAwesomeIcon icon={faLightbulb} className = 'eda-icon text-overview'/>
                
              </Card.Subtitle>
              <Card.Subtitle className="no-margin cameraicon">
                <FontAwesomeIcon icon={faCamera} className = 'eda-icon text-overview'/>
                
              </Card.Subtitle>
              
              
              </Card.Body>
            </Card>
          </Col> */}
      </Row>
    );
  }
}